.proj-image, .proj-selection {
    /* 8:5 Aspect Ratio */
    height: 100%;
    width: 100%;
    border: 1px solid #e7c9a9;
    padding: 0.6rem;
}

.proj-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin : 1rem;
}

.proj-icon1, .proj-icon2 {
    position: absolute;
    opacity: 0;
    color: white;
    font-size: 5.7rem;
    z-index: 2
}

.proj-icon1 {
    margin-left: -10rem;
}
.proj-icon2 {
    margin-left: 10rem;
}

.proj-icon1:hover, .proj-icon2:hover {
    color: var(--main-blue);
}

.proj-item-wrapper:hover .proj-icon1, .proj-item-wrapper:hover .proj-icon2 {
    opacity: .70 !important;
    transition: 0.2s ease-in-out !important;
    cursor: pointer !important;
}

.proj-selection {
    position: absolute;
    opacity : 0.0;
    background : black;
    top : 0;
    border: 4px solid #e7c9a9;
    cursor: pointer;
    z-index: 1;
}

.proj-item-wrapper:hover .proj-selection {
    opacity: .55 !important;
    transition: 0.4s ease-in-out !important;
}
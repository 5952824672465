
/* Photos styling */

#photos-title {
    font-weight: 800;
}

.photos-smiley-icon {
    width: 5rem;
    height: 5rem;
}

.photo-item {
    width: 230px;
    cursor: pointer;
    transition: 0.2s;
}

.photo-item-wrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.5rem;
    height: 490px;
}

.photo-enter {
    animation: photo-transition 10s;
 }

 .photos-scrolling-text {
    font-size: 14pt;
 }


 .photo-item:hover {
    opacity: 0.7;
}

@keyframes photo-transition{
    from {
        opacity: 0.1;
    }
    to {
        opacity: 1;
    }
}

@media(max-width: 481px ) {
    .photo-item {
        width: 150px;
    }
    .photo-item-wrapper {
        height: 330px;
    }
}

@media(max-width: 575px) {
    .modal .modal-xs {
        width: 250px;
        margin: auto;
    }
} 



/* Introduction CSS */

#skills-wrapper {
    /* height: 80rem; */
    /* height: 100%; */
    margin-top: 7rem;
    padding-bottom: 7rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

#skills-desc-wrapper {
    /* font-size: 20pt; */
    margin-right: 15px;
}

#skills-desc {
    font-weight: 800;
    /* font-size: var(--fs-xl-name);  */
    font-size: 25pt;
}

#skills-button {
    display: inline-block;
    font-family: 'Sudo Var';
    font-size: 22pt;
    color:black;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: .8rem;
    letter-spacing: .5rem; 
    width: 350px;
    margin-top: 3rem;
    padding: 1rem;
}

#skills-button:hover {
    color: white;
    background-color: var(--main-blue);
    transition: 0.5s;
}

.custom-br-name {
    display: block;
    margin-bottom: -.5rem;
}

.custom-br-position {
    display: block;
    margin-bottom: -.4rem;
}

#skills-image {
    height: 35rem;
    width: 20rem;
}

/* Media Queries */

@media(max-width: 1137px) {
    #skills-desc {
        font-size: 22pt;
    }
    #skills-button {
        width: 330px;
        font-size: 19pt;
    }
}

@media(max-width: 991px) {
    #skills-desc {
        font-size: 18pt;
    }
    #skills-button {
        width: 310px;
        font-size: 17pt;
    }
}

@media(max-width: 828px) {
    #skills-desc {
        font-size: 14pt;
    }
    #skills-button {
        width: 240px;
        font-size: 13pt;
    }
}

@media(max-width: 652px) {
    #skills-desc {
        font-size: 12pt;
    }
    #skills-button {
        width: 170px;
        font-size: 9pt;
    }
}

@media(max-width: 564px) {
    #skills-desc {
        font-size: 10pt;
    }
    #skills-button {
        width: 150px;
        font-size: 7pt;
    }
}

@media(max-width: 476px) {
    #skills-desc {
        font-size: 8pt;
    }
    #skills-button {
        width: 120px;
        font-size: 6pt;
    }
}

@media(max-width: 388px) {
    #skills-desc {
        font-size: 7pt;
    }
    #skills-button {
        width: 110px;
        font-size: 5pt;
    }
}

@media(max-width: 343px) {
    #skills-desc {
        font-size: 6pt;
    }
    #skills-button {
        width: 100px;
        font-size: 5pt;
    }
}

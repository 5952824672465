
/* CSS Styling for Navbar */

.navbar {
    /* position: relative; */
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    /* height: 4.5rem; */
}

.nav-contents {
    max-width : 100%;
    margin-left: 3%;
    margin-right: 3%;
}

.navbar-brand {
    font-size : 1.5rem;
    font-weight : 700;
}

.nav-link {
    font-size: 1.5rem;
    text-align: center;
    color: black;
}

/* Toggle */

.toggle {
    size: 1rem;
}



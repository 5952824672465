
/* Footer CSS Class */

#footer-wrapper {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.footer-text-content {
    font-size: 1.3rem;
    letter-spacing: 1.3px;
    font-family: 'Bitter';
    font-style: normal;
    font-weight: 700;
}

.footer-social-links {
    margin-right: 7%;
    float: right;
}

.footer-icon-image {
    width: 2.5rem;
    height: 2.5rem;
}

.footer-icon-image:hover, .footer-text-content:hover {
    cursor: pointer;
    filter: opacity(0.5) drop-shadow(0 0 0 var(--main-blue));
}